/**
 * HTML5 ✰ Boilerplate
 *
 * style.css contains a reset, font normalization and some base styles.
 *
 * Credit is left where credit is due.
 * Much inspiration was taken from these projects:
 * - yui.yahooapis.com/2.8.1/build/base/base.css
 * - camendesign.com/design/
 * - praegnanz.de/weblog/htmlcssjs-kickstart
*/
/**
 * html5doctor.com Reset Stylesheet (Eric Meyer's Reset Reloaded + HTML5 baseline)
 * v1.6.1 2010-09-17 | Authors: Eric Meyer & Richard Clark
 * html5doctor.com/html-5-reset-stylesheet/
*/
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: "";
    content: none;
}

ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
}

mark {
    background-color: #ff9;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

input, select {
    vertical-align: middle;
}

/**
 * Font normalization inspired by YUI Library's fonts.css: developer.yahoo.com/yui/
*/
body {
    font: 16px / 1.231 'lucida grande', tahoma;
    *font-size:small;
}

/* Hack retained to preserve specificity */
select, input, textarea, button {
    font: 99% 'lucida grande', tahoma;
}

/* Normalize monospace sizing:
 en.wikipedia.org/wiki/MediaWiki_talk:Common.css/Archive_11#Teletype_style_fix_for_Chrome */
pre, code, kbd, samp {
    font-family: monospace, sans-serif;
}

/**
 * Minimal base styles.
*/
/* Always force a scrollbar in non-IE */
html {
    overflow-y: scroll;
}

/* Accessible focus treatment: people.opera.com/patrickl/experiments/keyboard/test */
a:hover, a:active {
    outline: none;
}

ol {
    list-style-type: decimal;
}

ul {
    list-style: none;
}

small {
    font-size: 85%;
}

strong, th {
    font-weight: bold;
}

td {
    vertical-align: top;
}

/* Set sub, sup without affecting line-height: gist.github.com/413930 */
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

pre {
    /* www.pathf.com/blogs/2008/05/formatting-quoted-code-in-blog-posts-css21-white-space-pre-wrap/ */
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 15px;
}

textarea {
    overflow: auto;
}

/* www.sitepoint.com/blogs/2010/08/20/ie-remove-textarea-scrollbars/ */
.ie6 legend, .ie7 legend {
    margin-left: -7px;
}

/* Align checkboxes, radios, text inputs with their label by: Thierry Koblentz tjkdesign.com/ez-css/css/base.css  */
input[type =
"radio"] {
    vertical-align: text-bottom;
}

input[type =
"checkbox"] {
    vertical-align: bottom;
}

.ie7 input[type =
"checkbox"] {
    vertical-align: baseline;
}

.ie6 input {
    vertical-align: text-bottom;
}

/* Hand cursor on clickable input elements */
label, input[type =
"button"], input[type = "submit"], input[type = "image"], button {
    cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea {
    margin: 0;
}

/* Colors for form validity */
input:valid, textarea:valid {
}

input:invalid, textarea:invalid {
    border-radius: 1px;
    -moz-box-shadow: 0px 0px 5px red;
    -webkit-box-shadow: 0px 0px 5px red;
    box-shadow: 0px 0px 5px red;
}

.no-boxshadow input:invalid, .no-boxshadow textarea:invalid {
    background-color: #f0dddd;
}

/* j.mp/webkit-tap-highlight-color */
a:link {
    -webkit-tap-highlight-color: #FF5E99;
}

/* Make buttons play nice in IE:
 www.viget.com/inspire/styling-the-button-element-in-internet-explorer/ */
button {
    width: auto;
    overflow: visible;
}

/* Bicubic resizing for non-native sized IMG:
 code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/ */
.ie7 img {
    -ms-interpolation-mode: bicubic;
}

@font-face {
    font-family: 'CABNDWebBold';
    src: url('cabndwebbold-webfont.eot');
    src: url('cabndwebbold-webfont.eot?#iefix') format('embedded-opentype'),
        url('cabndwebbold-webfont.woff') format('woff'),
        url('cabndwebbold-webfont.ttf') format('truetype'),
        url('cabndwebbold-webfont.svg#CABNDWebBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * You might tweak these..
*/
body, select, input, textarea {
    /* #444 looks better than black: twitter.com/H_FJ/statuses/11800719859 */
    color: #333;
    /* Set your base font here, to apply evenly */
    font-family: 'CABNDWebBold';
}

/* Headers (h1, h2, etc) have no default font-size or margin; define those yourself */
h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

h1 {
    font-size: 24px;
}

h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}
