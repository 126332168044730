html, body {
  height: 100%;
  color: #222;
  font-family: 'Source Sans Pro', sans-serif;
}

.page-content {
  background: linear-gradient(217deg, rgba(243, 214, 243, 0.2), rgba(255,0,255,0) 20.71%),
            linear-gradient(127deg, rgba(253, 208, 206, 0.6), rgba(253, 208, 206,0) 20.71%),
            linear-gradient(336deg, rgba(255, 191, 0, 0.3), rgba(0,0,255,0) 30.71%);
}

#content {
  min-height: 100%;
  margin: 0 auto -80px;
  text-align: left;
}

nav.navigation {
  border-top: 40px solid $brand-color;
  width: 100%;

  #content {
    border-top: 35px solid lighten($brand-color-secondary, 15%);
  }
}

h2, h3, h4, h5, h6 {
  font-weight: 400;
}

h1 {
  font-size: 50px;
  font-weight: 700;
}

.homepage {
  text-align: center;

  a {
      font-weight: 500;
  }

  .recent-posts {
    .heading {
      margin-bottom: 15px;
    }
  }

  .contact-info {
    width: 50%;
    margin: 0 auto;
  }
}

.projects {
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
  width: 50%;
  background: #fff;
  padding: 2rem;
}

@media (max-width: 800px) {
  .homepage .contact-info, .projects {
    width: 90%;
  }
}

.projects-list {
  text-align: left;
  margin: 0 auto;
  // width: 70%;
}

.projects-list p {
  margin: 1rem 0 0;
}

.homepage a img {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-right: 1rem;
}

.homepage a {
  margin-right: 0.25rem;
  color: #555;
}

.footer {
  border-top: 10px solid #199CFF;
  display: none;
  overflow: auto;
  width: 100%;
}

.gradient-box {
  position: relative;
  box-sizing: border-box;

  $border: 5px;
  background-clip: padding-box; /* !importanté */
  border: solid $border transparent; /* !importanté */

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, rgb(255, 132, 132), rgb(255, 214, 137));
  }
}
